import type { JwtPayload } from 'jwt-decode'
import { jwtDecode } from 'jwt-decode'
import { defineStore } from 'pinia'
import API_ENDPOINTS from '@/api/client'
import axios from '@/api/axios'

interface Company {
  id: number
  name: string
  cif_nif: string
  address: string
}

interface User {
  id: number
  username: string
  email: string
  phone_number?: string | null
  first_name?: string
  last_name?: string
  registration_completed: boolean
  profile?: string | null
  province?: string | null
  interests: string[]
  marketing_offers_ok?: boolean
  company: Company
}

interface AuthState {
  access_token: string
  refresh_token: string
  user: User
}

export const useAuthStore = defineStore({
  id: 'auth',
  persist: true,
  state: (): AuthState => ({
    access_token: '',
    refresh_token: '',
    user: {
      id: 0,
      username: '',
      email: '',
      registration_completed: false,
      interests: [],
      company: {
        id: 0,
        name: '',
        cif_nif: '',
        address: '',
      },
    },
  }),
  getters: {
    isLoggedIn(state): boolean {
      return !!state.access_token
    },
    isAccessTokenValid(state: AuthState): boolean {
      if (!state.access_token)
        return false
      try {
        const { exp } = jwtDecode<JwtPayload>(state.access_token)

        return !!exp && Date.now() < exp * 1000
      }
      catch (e) {
        return false
      }
    },
    isrefresh_tokenValid(state: AuthState): boolean {
      if (!state.refresh_token)
        return false
      try {
        const { exp } = jwtDecode<JwtPayload>(state.refresh_token)

        return !!exp && Date.now() < exp * 1000
      }
      catch (e) {
        return false
      }
    },
  },
  actions: {
    async login(email: string, password: string) {
      const response = await axios.post(API_ENDPOINTS.LOGIN, { email, password }, {
        validateStatus: () => true,
      })

      if (response.status >= 200 && response.status < 300) {
        const { access_token, user, refresh_token } = response.data

        this.access_token = access_token
        this.user = user
        this.refresh_token = refresh_token
      }
      else {
        throw new Error('Login failed with status', { cause: response.status })
      }

      return response
    },
    async refresh() {
      const response = await axios.post(API_ENDPOINTS.REFRESH, {
        refresh: this.refresh_token,
      })

      const { access: access_token, refresh: refresh_token } = response.data

      this.$patch({
        access_token,
        refresh_token,
      })
    },
    async logout() {
      await axios.post(API_ENDPOINTS.LOGOUT)
      this.$reset()
      localStorage.clear()
    },
    async forgot_password(email: string, provider: string) {
      return await axios.post(API_ENDPOINTS.RESET_PASSWORD, { email, provider })
    },
    async reset_password(email: string, new_password1: string, new_password2: string, uid: string, token: string) {
      return await axios.post(API_ENDPOINTS.RESET_PASSWORD_CONFIRM, { email, new_password1, new_password2, uid, token }, {
        validateStatus: () => true,
      })
    },
    async register_company_user(userData: Omit<User, 'id' | 'company'>) {
      try {
        const companyId = this.user.company.id

        const userPayload = {
          ...userData,
          company: companyId,
        }

        const response = await axios.post(API_ENDPOINTS.COMPANY_USER, userPayload)

        return response.data
      }
      catch (error) {
        console.error('Error creating user:', error)
        throw error
      }
    },
    async unlink_company_user(userId: number) {
      try {
        const url = `${API_ENDPOINTS.COMPANY_USER}${userId}/`

        const response = await axios.delete(url)

        return response.data
      }
      catch (error) {
        console.error('Error unlinking user:', error)
        throw error
      }
    },
    async fetchUserData() {
      try {
        if (!this.access_token)
          throw new Error('No access token available')

        const response = await axios.get(`${API_ENDPOINTS.USERS}${this.user.id}/`, {
          headers: {
            Authorization: `Bearer ${this.access_token}`,
          },
        })

        this.user = response.data
      }
      catch (error) {
        console.error('Error fetching user data:', error)
      }
    },
  },
})
